import React from 'react';
import styled, { css } from 'styled-components';

import { useMedia } from '@tager/web-core';
import { convertThumbnailToPictureImage } from '@tager/web-modules';

import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

import { AboutCardPrimaryProps } from './types';
import AboutCardPrimaryBenefitList from './components/AboutCardPrimaryBenefitList';
import AboutCardPrimaryBenefitSlider from './components/AboutCardPrimaryBenefitSlider';

export function AboutCardPrimary({
  id,
  image,
  imageMobile,
  title,
  topText,
  benefits,
  bottomText,
}: AboutCardPrimaryProps) {
  const isTablet = useMedia('(max-width: 1259px)');

  return (
    <Component>
      {image && (
        <Image
          loading="lazy"
          desktop={convertThumbnailToPictureImage(image)}
          laptop={convertThumbnailToPictureImage(image)}
          tabletSmall={convertThumbnailToPictureImage(imageMobile ?? image)}
          mobile={convertThumbnailToPictureImage(imageMobile ?? image)}
          alt={title}
        />
      )}

      <Content withImage={!!image}>
        <Title>{title}</Title>
        <TopText>
          <Text dangerouslySetInnerHTML={{ __html: topText }} />
        </TopText>
        {benefits && benefits.length > 0 && (
          <Benefits>
            {!isTablet ? (
              <AboutCardPrimaryBenefitList benefits={benefits} />
            ) : (
              <AboutCardPrimaryBenefitSlider id={id} benefits={benefits} />
            )}
          </Benefits>
        )}
        {bottomText && (
          <BottomText>
            <Text dangerouslySetInnerHTML={{ __html: bottomText }} />
          </BottomText>
        )}
      </Content>
    </Component>
  );
}

const Component = styled.div`
  display: flex;

  ${media.tablet(css`
    flex-direction: column;
  `)}
`;

export const Image = styled(Picture)`
  flex: 0 0 375px;
  height: 453px;
  margin-right: 60px;

  ${media.tablet(css`
    flex: auto;
    height: auto;
    padding-top: 55.557%;
    margin: 0 0 20px;
  `)}

  picture,
  img {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }
`;

export const Content = styled.div<{ withImage?: boolean }>`
  ${({ withImage }) =>
    css`
      ${media.tablet(css`
        padding: ${withImage ? '0 20px' : '0'};
      `)}
    `}
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 35px;
  line-height: 45px;
  margin: 0;
  color: ${(props) => props.theme.black};

  ${media.tablet(css`
    font-size: 22px;
    line-height: 29px;
  `)}
`;

const TopText = styled.div`
  margin-top: 20px;

  ${media.tablet(css`
    margin-top: 10px;
  `)}
`;

const Text = styled.div`
  font-size: 16px;
  line-height: 21px;
  color: ${(props) => props.theme.gray2000};

  ul,
  ol {
    padding-left: 16px;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  a {
    color: ${colors.main};
  }
`;

const Benefits = styled.div`
  margin-top: 40px;

  ${media.tablet(css`
    margin-top: 30px;
  `)}
`;

const BottomText = styled.div`
  margin-top: 40px;
`;
