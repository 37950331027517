import * as Yup from 'yup';
import { omit } from 'lodash';

import {
  ServiceFormSecondaryFieldsConfig,
  ServiceFormSecondaryFieldsSchema,
} from './types';

export const getResultServiceFormSecondaryValues = (
  values: ServiceFormSecondaryFieldsSchema,
  fieldsConfig?: ServiceFormSecondaryFieldsConfig
) => {
  const paths: Array<keyof ServiceFormSecondaryFieldsSchema> = [];

  if (!fieldsConfig?.dealers?.length) {
    paths.push('dealer');
  }

  return omit(values, paths) as ServiceFormSecondaryFieldsSchema;
};

export const initialServiceFormSecondaryValues: ServiceFormSecondaryFieldsSchema =
  {
    name: '',
    phone: '',
    dealer: { label: '', value: '' },
    message: '',
  };

export const validationServiceFormSecondarySchema: Yup.SchemaOf<ServiceFormSecondaryFieldsSchema> =
  Yup.object().shape({
    name: Yup.string().required('Укажите имя'),
    phone: Yup.string().required('Укажите номер телефона'),
    dealer: Yup.object({
      label: Yup.string().required('Выберите автоцентр'),
      value: Yup.string().required('Выберите автоцентр'),
    }),
    message: Yup.string(),
  });
